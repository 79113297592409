import React from "react";
import MentorCard from "./MentorCard";

// Company Logos
import shopfiy from "../assets/img/mentors/logos/shopify.svg";
import microsoft from "../assets/img/mentors/logos/microsoft.svg";
import axiomZen from "../assets/img/mentors/logos/axiomZen.svg";
import qualcomm from "../assets/img/mentors/logos/qualcomm.svg";
import google from "../assets/img/mentors/logos/google.svg";
import rbc from "../assets/img/mentors/logos/rbc.svg";
import tenThousandCoffees from "../assets/img/mentors/logos/tenThousandCoffees.svg";
import kindred from "../assets/img/mentors/logos/kindred.svg";


// Mentor headshots

// const Geeneth = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027894/contributors/lnfn5lfxnp0hk1qgsz7m.jpg";
// const Chanuka = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027893/contributors/v4unttway5aytopkzvnj.jpg";
// const Samitha = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027892/contributors/te2pbgcdwsyyioz9xhxm.jpg";
// const Sama = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027894/contributors/fmojloud39jjhfa2vxlh.jpg";
// const Sandaruwan = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027892/contributors/u3xnb6y3cyaee52q6civ.jpg";
// const Trishan = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027893/contributors/iazgannmrdnvq54lcxtp.jpg";
// const Jeewanthi = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027892/contributors/cbemstpz8tndz6twsx9x.jpg";
// const Shehan = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027895/contributors/kon4awnbifxxubogkam0.jpg";

import Geeneth from "../assets/img/mentors/headshots/Geeneth.jpg";
import Chanuka from "../assets/img/mentors/headshots/Chanuka.jpg";
import Samitha from "../assets/img/mentors/headshots/Samitha.jpg";
import Sama from "../assets/img/mentors/headshots/Sama.jpg";
import Sandaruwan from "../assets/img/mentors/headshots/Sandaruwan.jpg";
import Trishan from "../assets/img/mentors/headshots/Trishan.jpg";
import Jeewanthi from "../assets/img/mentors/headshots/Jeewanthi.jpg";
import Shehan from "../assets/img/mentors/headshots/Shehan.jpg";

const QHACKS_COLORS = ["#00205b", "#fedb01", "#c81c2e"];

const mentors = [
  {
    name: "Lahiru Sandaruwan",
    title: "ARIS Contributor and Resource Person",
    headshot: Sandaruwan,
    companyLogo: qualcomm,
    companyName: "Qualcomm",
    companyUrl: "https://www.qualcomm.com/"
  },
  {
    name: "Sama Mayadunne",
    title: "ARIS Contributor, Resource Person and Research Team Member",
    headshot: Sama,
    companyLogo: google,
    companyName: "Google",
    companyUrl: "https://careers.google.com",
    logoMarginTop: "-20px",
    logoHeight: "80px"
  },
  {
    name: "Vishwa Jeewanthi",
    title: "ARIS Contributor and Research Team Member",
    headshot: Jeewanthi,
    companyLogo: microsoft,
    companyName: "Microsoft",
    companyUrl: "https://www.microsoft.com"
  },
  {
    name: "Samitha Hennayake",
    title: "ARIS Resource Person",
    headshot: Samitha,
    companyLogo: shopfiy,
    companyName: "Shopify",
    companyUrl: "https://www.shopify.com",
    logoHeight: "45px"
  },
  {
    name: "Chanuka Gunasekara",
    title: "ARIS Contributor and Resource Person",
    headshot: Chanuka,
    companyLogo: axiomZen,
    companyName: "AxiomZen",
    companyUrl: "https://www.axiomzen.co/",
    logoHeight: "25px",
    logoMarginTop: "10px"
  },
  {
    name: "Trishan Ranaweera",
    title: "ARIS Contributor and Resource Person",
    headshot: Trishan,
    companyLogo: tenThousandCoffees,
    companyName: "Ten Thousand Coffees",
    companyUrl: "https://www.tenthousandcoffees.com/",
    logoWidth: "60px",
    logoHeight: "60px"
  },
  {
    name: "Geeneth Punchihewa",
    title: "ARIS Contributor and Resource Person",
    headshot: Geeneth,
    companyLogo: rbc,
    companyName: "RBC",
    companyUrl: "https://www.rbc.com",
    logoWidth: "40px",
    logoHeight: "60px"
  },
  {
    name: "Shehan De Zoysa",
    title: "ARIS Contributor and Resource Person",
    headshot: Shehan,
    companyLogo: kindred,
    companyName: "Kindred.ai",
    companyUrl: "https://www.kindred.ai/"
  }
  // {
  //   name: "Enoch Tam",
  //   title: "Software Engineer",
  //   headshot: enoch,
  //   companyLogo: google,
  //   companyName: "Google",
  //   companyUrl: "https://careers.google.com",
  //   logoMarginTop: "-10px",
  //   logoHeight: "80px"
  // },
  // {
  //   name: "Ivan Zhang",
  //   title: "Machine Learning Infrastructure Engineer",
  //   headshot: ivan,
  //   companyLogo: cortex,
  //   companyName: "Cortex",
  //   companyUrl: "https://www.cortexlabs.com",
  //   logoMarginTop: "-15px",
  //   logoHeight: "60px"
  // },
  // {
  //   name: "Lydia Noureldin",
  //   title: "Software Development Consultant",
  //   headshot: lydia,
  //   companyLogo: munvo,
  //   companyName: "Munvo",
  //   companyUrl: "https://munvo.com/",
  //   logoMarginTop: "15px",
  //   logoHeight: "30px"
  // },
  // {
  //   name: "Ese Okonofua",
  //   title: "Software Engineer",
  //   headshot: ese,
  //   companyLogo: microsoft,
  //   companyName: "Microsoft",
  //   companyUrl: "https://microsoft.com",
  //   logoMarginTop: "10px"
  // },
  // {
  //   name: "Alex Adusei",
  //   title: "Software Engineer",
  //   headshot: alex,
  //   companyLogo: opener,
  //   companyName: "Opener",
  //   companyUrl: "https://www.opener.aero/",
  //   logoHeight: "60px"
  // },
  // {
  //   name: "Emma Koza",
  //   title: "Software Engineer",
  //   headshot: emma,
  //   companyLogo: opencare,
  //   companyName: "Opencare.com",
  //   companyUrl: "https://opencare.com",
  //   logoWidth: "200px",
  //   logoMarginTop: "5px"
  // },
  // {
  //   name: "Anuj Arora",
  //   title: "Consulting Analyst",
  //   headshot: aj,
  //   companyLogo: accenture,
  //   companyName: "Accenture",
  //   companyUrl: "https://www.accenture.com/",
  //   logoHeight: "40px",
  //   logoMarginTop: "0"
  // },
  // {
  //   name: "Khalid Karim",
  //   title: "Senior Product Manager",
  //   headshot: khalid,
  //   companyLogo: uber,
  //   companyName: "Uber",
  //   companyUrl: "https://uber.com",
  //   logoHeight: "30px",
  //   logoMarginTop: "10px"
  // }
];

export default () => (
  <div css={{ marginBottom: "16px" }}>
    <h1 css={{ fontWeight: "600", fontSize: "28px", textAlign: "center" }}>
      Resource Persons and Contributors
    </h1>
    <div
      css={{
        display: "grid",
        "@media (min-width: 1284px)": {
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"
        },
        "@media (max-width: 1284px)": {
          gridTemplateColumns: "auto auto"
        },
        "@media (max-width: 912px)": {
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))"
        }
      }}
    >
      {mentors.map((mentor, i) => (
        <MentorCard
          {...mentor}
          key={mentor.name}
          borderColor={QHACKS_COLORS[i % 3]}
        />
      ))}
    </div>
  </div>
);
