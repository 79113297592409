import React from "react";
import SpeakerCard from "./SpeakerCard";
import SpeakerSlider from "./SpeakerSlider";
import Mentors from "./Mentors";

import cubes3webp from "../assets/img/icons/cubes-3.webp";
import cubes3png from "../assets/img/icons/cubes-3.png";

import ContentWrapper from "./ContentWrapper";

// const eranga = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027432/Management%20Committe/hjyhnwbnwwpppkuhvczh.jpg";
// const yasith = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027432/Management%20Committe/oaboi4osjahfvzcpeyzs.jpg";
// const amila = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027430/Management%20Committe/r6z7f7poyy9jwjwzt8vn.jpg";
// const pulasthi = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027433/Management%20Committe/ojteacsj9wtgg68sxsrx.jpg";
// const sithum = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027432/Management%20Committe/twnzlvj8qpnhsthdxfyi.jpg";
// const sankha = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027434/Management%20Committe/pkvvhmisg4bsn7sw5iti.jpg";
// const harsha = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027431/Management%20Committe/tocsexlonlpo1iosot94.jpg";
// const harshana = "https://res.cloudinary.com/astronomerslk/image/upload/v1563027431/Management%20Committe/j04xjyero3anrnzbfk5r.jpg";

import eranga from "../assets/img/speakers/eranga.jpg";
import yasith from "../assets/img/speakers/yasith.jpg";
import amila from "../assets/img/speakers/amila.jpeg";
import pulasthi from "../assets/img/speakers/pulasthi.jpeg";
import sithum from "../assets/img/speakers/sithum.jpg";
import sankha from "../assets/img/speakers/sankha.jpg";
import harsha from "../assets/img/speakers/harsha.jpg";
import harshana from "../assets/img/speakers/harshana.jpg";

const speakers = [

  {
    name: "Eranga Jayashantha",
    title: "President of ARIS, B.Sc (Hons.) special degree in Microbiology",
    description:
      "Graduated from 34th ISYA (IAU) in Modern Astronomy & Astrophysics at NARIT - Thailand, Honorable mention Diploma in Astronomy and Astrophysics (IOAA-China), Former NPoC for Sri Lanka at Space Generation Advisory Council (UN) Former trainer and lecturer for Sri Lanka Astronomy and Astrophysics Olympiad at IPSL.",
    image: eranga
  },

  {
    name: "Yasith Ramawickrama",
    title: "NPoC for Sri Lanka at SGAC and Secretary of ARIS",
    description:
      "Yasith was the NPoC for Sri Lanka at Space Generation Advisory Council (SGAC). He starts his Astronomy education, when he was at grade 6 in Mahinda College, Galle and since then he representing Sri Lanka in various Space Science and Astronomy congresses, workshops and Summer Schools all over the World. More recently he led the 5th Asia- Pacific Region Space Generation Workshop, 2018 (5th AP-SGW) in Singapore and joined as a Summer Researcher in Astronomy at Leiden University, The Netherlands.",
    image: yasith
  },

  {
    name: "Amila Karunaranthe",
    title: "Joint Major in Electronics and Computer Science, Wayamba University of Sri Lanka",
    description:
      "Amila is an Applications Engineer. He was a bronze medalist in 2010 SLOAA. Apart from astronomy, he is also a licensed amateur radio operator. He is experimenting in radio astronomy where the both areas intersects.",
    image: amila
  },

  {
    name: "Pulasthi Kanattage",
    title: "B.Sc. Surveying Sciences (Speacialization Remote Sensing & Photogrammetry), Event Coordinator of ARIS",
    description:
      "Pulasthi is a well known name in Sri Lankan Astronomy, He founded Ahasa Magazine in early 2000 to improve astronomical knowaledge amoung young Sri Lankans. He is also very talented astrophotograper. and former president of AAA",
    image: pulasthi
  },
  {
    name: "Sankha Karunasekara",
    title: "BSc Engineering (Hons) Specialized in Computer Engineering,Research Coordinator of ARIS",
    description:
      "Apart from being a Computer Engineer Sankha has equally excelled in Astronomy and Astrophysics. He Represented Sri Lanka in 4th International Astronomy and Astrophysics Olympiad (IOAA) held in Beijing China, and he is also two time gold medalist in SLOAA. in Last decade Sankha has actively engaged in conducting lectures, organizing night camps and other astronomical activities around the Island to improve astronomy knowledge of young Sri Lankans.",
    image: sankha
  },
  {
    name: "Dr Sithum Gunarathne",
    title: "MBBS, University of Colombo, Chief Organizer of ARIS",
    description:
      "Sithum is a Medical officer, graduated with MBBS degree from Faculty of Medicine, University of Colombo, Sri Lanka.",
    image: sithum
  },
  {
    name: "Harshana Jayarathne",
    title: "Bachelor of Law, Law College Sri Lanka. Legal Officer",
    description:
      "Silver medalist at SLOAA competition in 2012, former president of AAA",
    image: harshana
  },
  {
    name: "Harsha Premachandra",
    title: "Editor at ARIS",
    description:
      "Diploma in Journalism (University of Colombo), HND In advertising and Multimedia (NIBM)",
    image: harsha
  }

];

const OurTeam = () => (
  <section
    css={{
      backgroundColor: "#f8f8f8",
      position: "relative"
    }}
    id="team"
  >
    <ContentWrapper>
      <h1
        css={{
          paddingTop: "40px",
          paddingBottom: "48px",
          textAlign: "center",
          position: "relative",
          zIndex: "3",
          "@media(max-width: 820px)": {
            paddingTop: "24px",
            paddingBottom: "24px"
          }
        }}
      >
        Our Team
      </h1>

      <h1 css={{ fontWeight: "600", fontSize: "28px", padding: "32px 8px", textAlign: "center" }}>
        Management Committee
      </h1>
      {/* Desktop */}
      <div
        css={{
          "@media(max-width: 1120px)": {
            display: "none"
          }
        }}
      >
        <SpeakerSlider speakers={speakers} />
      </div>
      {/* Mobile */}
      <div
        css={{
          paddingBottom: "30px",
          paddingTop: "10Px",
          "@media(min-width: 1120px)": { display: "none" }
        }}
      >
        <SpeakerSlider speakers={speakers} />
      </div>
      <picture
        css={{
          zIndex: "1",
          width: "235px",
          height: "289px",
          position: "absolute",
          bottom: "-250px",
          right: "62px",
          display: "block",
          "@media(max-width: 1000px)": {
            display: "none"
          }
        }}
      >
        <source srcSet={cubes3webp} type="image/webp" />
        <source srcSet={cubes3png} type="image/png" />
        <img
          css={{
            "@media(max-width: 1000px)": {
              display: "none"
            }
          }}
          src={cubes3png}
          alt="Floating cubes"
        />
      </picture>
      <Mentors />
    </ContentWrapper>
  </section>
);

export default OurTeam;
