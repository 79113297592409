import React, { Component, Fragment } from "react";
import Slider from "react-slick";

import SpeakerCard from "./SpeakerCard";
import SpeakerSliderDots from "./SpeakerSliderDots";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class SpeakerSlider extends Component {
  constructor(props) {
    super(props);
  }

  handleChange = (currentIndex) => this.setState({ currentIndex });

  componentWillMount() {
    this.setState({ currentIndex: 0 });
  }

  onSpeakerSliderDotsClicked = (num) => () => {
    this.slider.slickGoTo(num);
  };

  render() {
    const desktopSettings = {
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      speed: 500,
      afterChange: (direction) => {
        this.handleChange(direction);
      }
    };

    const mobileSettings = {
      dots: false,
      arrows: true,
      infinite: true,
      autoplay: true,
      afterChange: (direction) => {
        this.handleChange(direction);
      }
    };

    return (
      <Fragment>
        {/*Desktop*/}
        <div css={{
          width: "100%",
          position: "relative",
          zIndex: 3,
          "@media(max-width: 1120px)": {
            display: "none"
          }}}>
          <SpeakerSliderDots
            speakers={this.props.speakers}
            currentIndex={this.state.currentIndex}
            onClick={this.onSpeakerSliderDotsClicked}
          />
          <Slider {...desktopSettings} ref={(slider) => (this.slider = slider)}>
            {this.props.speakers.map((speaker) => (
              <SpeakerCard {...speaker} key={speaker.name} />
            ))}
          </Slider>
        </div>

        <div css={{
          width: "100%",
          position: "relative",
          zIndex: 3,
          "@media(min-width: 1120px)": {
            display: "none"
          }
        }}>
          {this.props.speakers.map((speaker) => (
            <SpeakerCard {...speaker} key={speaker.name} />
          ))}
        </div>
      </Fragment>
    );
  }
}

export default SpeakerSlider;
