import React from "react";
import ContentWrapper from "../ContentWrapper";
import Disqus from "disqus-react";

const DisqusComments = (props) => (
  <section id="discussion" css={{ backgroundColor: "#ffffff" }}>
    <ContentWrapper>
      <h1>Discussion</h1>
      <div
        css={{
          padding: "36px 16px",
          display: "grid",
          gridTemplateColumns: "1fr",
          "@media(max-width: 1190px)": {
            display: "block"
          }
        }}
      >
        <Disqus.DiscussionEmbed
          shortname={props.disqusShortname}
          config={props.disqusConfig}
        />
      </div>
    </ContentWrapper>
  </section>
);

export default DisqusComments;
