import React from "react";

import SponsorLink from "./SponsorLink";
import ActionButton from "./ActionButton";
import ContentWrapper from "./ContentWrapper";

import tdLogo from "../assets/img/sponsors/td.svg";
import qicLogo from "../assets/img/sponsors/qic.svg";
import dclLogo from "../assets/img/sponsors/dcl.svg";
import eceLogo from "../assets/img/sponsors/ece.svg";
import cseLogo from "../assets/img/sponsors/cse.svg";
// import amsLogo from "../assets/img/sponsors/ams.svg";
import iganLogo from "../assets/img/sponsors/igan.svg";
import mmieLogo from "../assets/img/sponsors/mmie.svg";
import snapLogo from "../assets/img/sponsors/snap.svg";
import telusLogo from "../assets/img/sponsors/telus.svg";
import mlhLogo from "../assets/img/sponsors/mlhText.svg";
import githubLogo from "../assets/img/sponsors/github.svg";
import sketchLogo from "../assets/img/sponsors/sketch.svg";
import ritualLogo from "../assets/img/sponsors/ritual.svg";
import loopioLogo from "../assets/img/sponsors/loopio.svg";
import tucowsLogo from "../assets/img/sponsors/tucows.svg";
import stdlibLogo from "../assets/img/sponsors/stdlib.svg";
import mosaicLogo from "../assets/img/sponsors/mosaic.svg";
import apolloLogo from "../assets/img/sponsors/apollo.svg";
import googleLogo from "../assets/img/sponsors/google.svg";
import indicoLogo from "../assets/img/sponsors/indico.svg";
import sodexoLogo from "../assets/img/sponsors/sodexo.svg";
import pccLogo from "../assets/img/sponsors/pcc.png";
import ratehubLogo from "../assets/img/sponsors/ratehub.svg";
import sunLifeLogo from "../assets/img/sponsors/sunlife.svg";
import wolframLogo from "../assets/img/sponsors/wolfram.svg";
import wakeupsLogo from "../assets/img/sponsors/wakeups.svg";
import deloitteLogo from "../assets/img/sponsors/deloitte.svg";
import balsamiqLogo from "../assets/img/sponsors/balsamiq.svg";
import kingstonLogo from "../assets/img/sponsors/kingston.svg";
import bloombergLogo from "../assets/img/sponsors/bloomberg.svg";
import consensysLogo from "../assets/img/sponsors/consensys.svg";
import ycombinator from "../assets/img/sponsors/ycombinator.svg";
import orangeGateLogo from "../assets/img/sponsors/orangeGate.svg";
import scotiabankLogo from "../assets/img/sponsors/scotiabank.svg";
import onePasswordLogo from "../assets/img/sponsors/1password.svg";
import stickermuleLogo from "../assets/img/sponsors/stickermule.svg";
import nationalBankLogo from "../assets/img/sponsors/nationalbank.svg";
// import mountKLogo from "../assets/img/sponsors/mountKLogo.png";
import schoolOfComputingLogo from "../assets/img/sponsors/schoolOfComputing.svg";
import ramenVenturesLogo from "../assets/img/sponsors/ramenVentures.png";
// import studiozlkLogo from "../assets/img/sponsors/studiozlkLogo.jpg";
import shopifyLogo from "../assets/img/sponsors/shopify.svg";
import foundersBetaLogo from "../assets/img/sponsors/foundersBeta.png";
import coinsquareLogo from "../assets/img/sponsors/coinsquare.png";
import munvoLogo from "../assets/img/sponsors/munvo.png";
import asusAmsEngSocLogo from "../assets/img/sponsors/asusAmsEngSoc.png";

const mountKLogo = "https://res.cloudinary.com/astronomerslk/image/upload/v1563028295/partners/ccf2uo5iss89jbs4atkc.png";
const studiozlkLogo = "https://res.cloudinary.com/astronomerslk/image/upload/v1563028295/partners/nqa63gwcyozhwajbqn4n.jpg";

const Sponsors = () => (
  <section
    id="partners"
    css={{
      backgroundColor: "#f8f8f8",
      " img": {
        transition: "0.3s",
        ":hover": {
          transform: "scale(1.1)"
        }
      }
    }}
  >
    <ContentWrapper>
      <div
        css={{
          marginLeft: "auto",
          marginRight: "auto",
          textAlign: "center",
          paddingTop: "40px"
        }}
      >
        {/* Sponsors */}
        <div>
          <h1>Our Partners</h1>

          {/* Tera */}
          <div
            css={{
              marginTop: "70px",
              marginBottom: "40px",
              "@media(max-width: 460px)": {
                marginBottom: "60px"
              }
            }}
          >

            {/* Startup Row 3 */}
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr 1fr",
                marginBottom: "40px",
                "@media(max-width: 920px)": {
                  gridTemplateColumns: "1fr",
                  width: "60%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "10px"
                },
                "@media(max-width: 610px)": {
                  gridTemplateColumns: "1fr",
                  width: "90%",
                  marginBottom: "0px"
                }
              }}
            >
              {/*
              <SponsorLink url="https://munvo.com">
                <img
                  src={munvoLogo}
                  css={{
                    width: "150px",
                    marginTop: "-20px",
                    "@media(max-width: 920px)": {
                      marginTop: 0,
                      width: "120px"
                    },
                    "@media(max-width: 610px)": {
                      width: "100px"
                    }
                  }}
                  alt="Munvo Logo"
                />
                </SponsorLink>*/}
              <SponsorLink url="https://www.facebook.com/StudioZLk/">
                <img
                  src={studiozlkLogo}
                  css={{
                    width: "180px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    "@media(max-width: 920px)": {
                      width: "140px",
                      marginTop: "20px",
                      marginBottom: "5px"
                    },
                    "@media(max-width: 610px)": {
                      width: "90px"
                    }
                  }}
                  alt="StudiozLk"
                />
                <h4>Official Photographic Partner</h4>
              </SponsorLink>
              <SponsorLink url="http://www.mountk.com/">
                <img
                  src={mountKLogo}
                  css={{
                    width: "180px",
                    marginTop: "10px",
                    marginBottom: "5px",
                    "@media(max-width: 920px)": {
                      width: "140px",
                      marginTop: "20px",
                      marginBottom: "5px"
                    },
                    "@media(max-width: 610px)": {
                      width: "90px"
                    }
                  }}
                  alt="Mount K Logo"
                />
                <h4>Official Location Partner</h4>
              </SponsorLink>
            </div>
          </div>
        </div>
      </div>
    </ContentWrapper>
  </section>
);

export default Sponsors;
